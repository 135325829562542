document.addEventListener('DOMContentLoaded', () => {
    let page = 2;
    let currentTaxonomy = '';
    let currentSlug = '';

    // DOM要素の管理を一元化
    const doms = {
        categoryButton: document.getElementById('categoryButton'),
        readMoreButton: document.getElementById('read-more'),
        blogGrid: document.querySelector('.blog-grid'),
        blogFeatured: document.querySelector('.blog-featured'),
        popupMenu: document.getElementById('popup-menu'),
        closePopupButton: document.getElementById('close-popup'),
        loading: document.getElementById('loading'),
        logoSvg: document.getElementById('ao-logo'),
        menuToggleButton: document.getElementById('menu-toggle'),
        toggleButton: document.getElementById('toggle-categories'),
        categoryList: document.querySelector('.faq-categories'),
        categoryLinks: document.querySelectorAll('.faq-categories a')
    };

    let relatedBlogsSwiper = null;
    const isArchivePage = typeof window.isArchivePage !== 'undefined' ? window.isArchivePage : false;

    // FAQカテゴリ選択メニューのクリックイベント
    if (doms.toggleButton) {
        doms.toggleButton.addEventListener('click', () => doms.categoryList.classList.toggle('open'));
    }

    // カテゴリリンクのスクロールとメニューの閉じる処理
    doms.categoryLinks.forEach(link => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            doms.categoryList.classList.remove('open');

            const targetId = link.getAttribute('href');
            const targetElement = document.querySelector(targetId);

            if (targetElement) {
                const headerOffset = window.innerWidth <= 768 ? 220 : 30;
                const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });

    const initializeSwiper = (selector, config) => {
        const swiperElement = document.querySelector(selector);
        if (!swiperElement) {
            console.warn(`Swiper element not found: ${selector}`);
            return null;
        }
        try {
            const swiper = new Swiper(swiperElement, config);
            console.log(`Initialized Swiper on: ${selector}`);
            return swiper;
        } catch (error) {
            console.error(`Failed to initialize Swiper on: ${selector}`, error);
            return null;
        }
    };

    // 関連ブログ用Swiperの設定
    const relatedBlogsSwiperConfig = {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            1280: {
                slidesPerView: 3,
                spaceBetween: 0
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 0
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 0
            }
        },
        watchOverflow: true
    };

    /**
     * Swiperの初期化と無効化を管理する関数
     */
    const handleSwiper = () => {
        const windowWidth = window.innerWidth;

        // INDEXページのみメインビジュアルのSwiperを有効
        if (document.querySelector('.pc-swiper') && document.querySelector('.sp-swiper')) {
            // PC用のSwiper初期化（autoplay停止状態で開始）
            const pcSwiper = new Swiper('.pc-swiper', {
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                speed: 1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            });

            // スマホ用のSwiper初期化（autoplay停止状態で開始）
            const spSwiper = new Swiper('.sp-swiper', {
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                speed: 1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            });

            // Swiperの自動再生を初期停止
            pcSwiper.autoplay.stop();
            spSwiper.autoplay.stop();

            // アニメーションが終わった後に再生開始をanimation.jsから制御
            window.startSwiper = () => {
                setTimeout(() => {
                    pcSwiper.slideTo(0); // 最初のスライドにリセット
                    spSwiper.slideTo(0);
                    pcSwiper.autoplay.start();
                    spSwiper.autoplay.start();
                },5000);

            };
        }

        // 関連ブログSwiperがあるページでのみ処理
        if (document.querySelector('.related-blogs-slider')) {
            if (windowWidth <= 768 && relatedBlogsSwiper) {
                relatedBlogsSwiper.destroy(true, true);
                relatedBlogsSwiper = null;
            } else if (!relatedBlogsSwiper) {
                relatedBlogsSwiper = initializeSwiper('.related-blogs-slider', relatedBlogsSwiperConfig);
            }
        }
    };

    window.addEventListener('load', handleSwiper);
    window.addEventListener('resize', handleSwiper);

    const setButtonStyle = () => {
        if (!doms.categoryButton) return;
        doms.categoryButton.style.cssText = `
            display: block;
            width: fit-content;
            margin: 0 auto;
            padding: ${window.innerWidth <= 768 ? '18px 22px' : '16px 44px'};
        `;
    };

    const initializeButton = () => {


        // 初期状態での表示チェック
        const currentPosts = doms.blogFeatured.children.length; // 現在表示されている投稿数

        console.log(currentPosts);

        if (currentPosts === 0) {
            doms.categoryButton.style.display = 'none'; // ボタンを非表示
            doms.blogFeatured.innerHTML = '<p class="not-found">該当する記事が見つかりませんでした。</p>'; // メッセージを表示
        } else {
            doms.categoryButton.style.display = 'block'; // ボタンを表示
        }

        if (!doms.categoryButton) return;

        doms.categoryButton.href = `${ao_design_ajax.home_url}blog`;
        doms.categoryButton.textContent = 'すべての記事一覧へ';
        setButtonStyle();
    };


    const updateCategoryButton = (taxonomy, slug, name) => {
        if (!doms.categoryButton) return;
        const urlTaxonomy = taxonomy === 'category' ? 'categories' : 'tags';
        doms.categoryButton.href = taxonomy === 'category' && slug === 'all' ?
            `${ao_design_ajax.home_url}blog` :
            `${ao_design_ajax.home_url}${urlTaxonomy}/${slug}`;
        doms.categoryButton.textContent = taxonomy === 'category' && slug === 'all' ?
            'すべての記事一覧へ' :
            `${name}の記事一覧へ`;
        setButtonStyle();
    };

    const toggleCategoryButtonVisibility = (hasPosts) => {
        if (hasPosts) {
            doms.categoryButton.style.display = hasPosts ? 'block' : 'none';
        }
    };

    const ajaxRequest = (action, params, callback) => {
        const xhr = new XMLHttpRequest();

        xhr.open('POST', ao_design_ajax.ajax_url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 400) {
                try {
                    const response = JSON.parse(xhr.responseText);
                    callback(response);
                } catch (error) {}
            } else {

            }
        };

        // Nonceの追加 (WordPressのlocalize_scriptで`ao_design_ajax.nonce`を渡していることが前提)
        const nonce = ao_design_ajax.nonce || '';

        // パラメータに action が含まれていない場合に追加
        if (!params.includes('action=')) {
            params = `action=${encodeURIComponent(action)}&${params}`;
        }

        // リクエストパラメータを構築
        const requestParams = `${params}&nonce=${encodeURIComponent(nonce)}`;
        // console.log(`requestParams:` + requestParams);

        // リクエストの送信
        xhr.send(requestParams);
    };

    // 投稿を追加取得する処理
    const loadMorePosts = () => {
        const taxonomy = doms.readMoreButton.getAttribute('data-taxonomy') || currentTaxonomy;
        const slug = doms.readMoreButton.getAttribute('data-slug') || currentSlug;

        ajaxRequest('load_more_posts', `page=${page}&taxonomy=${taxonomy}&slug=${slug}`, (response) => {
            const currentPosts = doms.blogGrid.children.length; // 現在表示されている投稿数
            const totalPosts = parseInt(doms.blogGrid.getAttribute('data-total-posts') || '0', 10); // 全投稿数

            // console.log('totalPosts:', totalPosts);
            // console.log('currentPosts before adding:', currentPosts);

            if (response.posts && response.posts.trim() !== '') {
                const newPosts = document.createElement('div');
                newPosts.innerHTML = response.posts;
                newPosts.querySelectorAll('.blog-post').forEach(post => doms.blogGrid.appendChild(post));
                page++;

                // 現在の表示数を更新
                const updatedCurrentPosts = doms.blogGrid.children.length;
                // console.log('currentPosts after adding:', updatedCurrentPosts);

                // 全ての投稿がロードされている場合、READ MOREボタンを非表示
                if (updatedCurrentPosts >= totalPosts) {
                    doms.readMoreButton.style.display = 'none';
                } else {
                    doms.readMoreButton.style.display = 'block';
                }
            } else {
                doms.readMoreButton.style.display = 'none';
            }
        });
    };


    // READ MOREボタンの初期化関数
    const initializeReadMoreButton = () => {
        const totalPosts = parseInt(doms.blogGrid.getAttribute('data-total-posts') || '0', 10);
        const currentPosts = doms.blogGrid.children.length; // 現在表示されている投稿数

        // console.log('totalPosts: ' + totalPosts);
        // console.log('currentPosts: ' + currentPosts);

        // まだ表示されていない投稿がある場合にREAD MOREボタンを表示
        if (currentPosts >= totalPosts) {
            doms.readMoreButton.style.display = 'none'; // 全ての投稿が表示されている場合は非表示
        } else {
            doms.readMoreButton.style.display = 'block'; // まだ投稿が残っている場合は表示
        }
    };



    // カテゴリやタグのリンククリック時の処理
    const handleLinkClick = (event) => {
        const link = event.target.closest('a');
        if (!link) return; // リンクがない場合は処理を終了

        const href = link.getAttribute('href');
        const taxonomy = link.getAttribute('data-taxonomy');
        const slug = link.getAttribute('data-slug');

        // アーカイブページの場合は通常のリンク遷移を許可
        if (href && taxonomy === null && slug === null) return;

        event.preventDefault(); // デフォルトのリンク動作をキャンセル

        if (!taxonomy || !slug) {
            // console.error("Taxonomy or slug is null");
            return; // タクソノミーまたはスラッグが無効な場合は処理を中止
        }

        // フィルタリング処理を呼び出す
        handlePostFetch(taxonomy, slug, 'blog');

        updateCategoryButton(taxonomy, slug, link.textContent);
    };



    const handlePostFetch = (taxonomy, slug, scrollTarget = null) => {
        let action, targetElement;

        // ページの種類に応じたAPIアクションとターゲット要素を判定
        const isArchivePage = window.ao_design_ajax ?.isArchivePage || false;
        const isBlogPage = window.ao_design_ajax ?.isBlogPage || false;

        if (isArchivePage || isBlogPage) {
            action = 'blog_filter_posts';
            targetElement = doms.blogGrid;
        } else {
            action = 'ao_design_filter_posts';
            targetElement = doms.blogFeatured;
        }

        // console.log(`window.isArchivePage: ${isArchivePage}`);
        // console.log(`window.isBlogPage: ${isBlogPage}`);

        // ターゲット要素が存在しない場合は処理を中止
        if (!targetElement) {
            // console.error('Error: Target element not found.');
            return;
        }

        // リクエストパラメータの作成
        const params = new URLSearchParams({
            action: action,
            taxonomy: taxonomy,
            slug: slug,
            nonce: ao_design_ajax.nonce
        });

        // console.log(`Fetching posts with params: ${params.toString()}`);

        // Ajaxリクエスト実行
        fetch(ao_design_ajax.ajax_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: params.toString()
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // console.log('Fetched data:', data);

                // 投稿が取得できた場合、DOMを更新
                if (data.posts) {
                    targetElement.innerHTML = data.posts;
                    // console.log(`Updated total posts to: ${data.total_posts}`);

                    // READ MORE ボタンの状態を更新
                    updateReadMoreButton(taxonomy, slug, data.total_posts, data.current_posts);

                    const hasPosts = data.posts.trim() !== '';

                    toggleCategoryButtonVisibility(hasPosts);
                } else {
                    targetElement.innerHTML = '<p class="not-found">該当する記事が見つかりませんでした。</p>';
                    toggleCategoryButtonVisibility(false);
                }

                // スクロールターゲットが指定されている場合の処理
                if (scrollTarget) {
                    const target = document.getElementById(scrollTarget);
                    if (target) {
                        target.scrollIntoView({
                            behavior: 'smooth'
                        });
                    } else {
                        // console.warn(`Target element with ID "${scrollTarget}" not found.`);
                    }
                }
            })
            .catch(error => {
                // console.error('Error fetching posts:', error);
                toggleCategoryButtonVisibility(false);
            });
    };





    // READ MORE ボタンの更新関数
    const updateReadMoreButton = (taxonomy, slug, totalPosts = 0, currentPosts = 0) => {
        if (!doms.readMoreButton) return; // ボタンがない場合は処理を終了

        // ボタンのデータ属性を更新
        doms.readMoreButton.setAttribute('data-taxonomy', taxonomy);
        doms.readMoreButton.setAttribute('data-slug', slug);

        // 現在の投稿数が総投稿数に達したらボタンを無効化
        if (currentPosts >= totalPosts) {
            doms.readMoreButton.style.display = 'none';
        } else {
            doms.readMoreButton.style.display = 'inline-block';
        }
    };



    const togglePopupMenu = (show) => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        doms.popupMenu.style.top = show ? `${scrollTop}px` : '';
        doms.popupMenu.classList.toggle('show', show);
        document.body.style.overflow = show ? 'hidden' : 'auto';

        if (!show) {
            doms.popupMenu.addEventListener('transitionend', function onTransitionEnd() {
                doms.popupMenu.style.visibility = 'hidden';
                doms.popupMenu.removeEventListener('transitionend', onTransitionEnd);
            }, {
                once: true
            });
        } else {
            doms.popupMenu.style.visibility = 'visible';
        }
    };

    window.addEventListener('load', () => {
        const svgElements = document.querySelectorAll(
            '.logo-svg path, .logo-svg polygon, .logo-svg rect'
        );

        const WAIT_TIME_AFTER_ANIMATION = 1000; // アニメーション後の待機時間(ms)

        // スクロールを無効化する関数
        const disableScroll = () => {
            document.body.style.overflow = 'hidden';
        };

        // スクロールを有効化する関数
        const enableScroll = () => {
            document.body.style.overflow = '';
        };

        // ローディング中はスクロールを無効化
        disableScroll();

        // アニメーション完了後にローディング画面を非表示にする関数
        const onComplete = () => {
            const loading = document.querySelector('.loading');
            loading.style.opacity = 0;
            enableScroll(); // スクロールを有効化

            setTimeout(() => {
                loading.style.display = 'none';

                // ✅ Swiperの自動再生開始
                if (window.startSwiper) {
                    window.startSwiper();
                }
            }, 400); // フェードアウト後に開始
        };

        // 各要素にアニメーションを適用
        svgElements.forEach((element, index) => {
            element.style.transitionDelay = `${index * 0.1}s`;
            element.classList.add('svg-animated');
        });

        const logoSvg = document.querySelector('.logo-svg');
        if (logoSvg) {
            logoSvg.classList.add('active'); // activeクラスを追加

            // アニメーション時間 + 待機時間後にローディング画面を非表示にする
            const totalAnimationTime = svgElements.length * 100 + 700; // アニメーション時間
            setTimeout(onComplete, totalAnimationTime + WAIT_TIME_AFTER_ANIMATION);
        }
    });


    if (doms.menuToggleButton) doms.menuToggleButton.addEventListener('click', () => togglePopupMenu(true));
    if (doms.closePopupButton) doms.closePopupButton.addEventListener('click', () => togglePopupMenu(false));

    document.querySelectorAll('.blog-cat-link a, .blog-tag-link a').forEach(link => {
        link.addEventListener('click', handleLinkClick);
    });

    if (doms.readMoreButton) doms.readMoreButton.addEventListener('click', loadMorePosts);

    if (doms.categoryButton) initializeButton();
    if (isArchivePage) initializeReadMoreButton();
    initializeButton();
});
